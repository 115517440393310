import React, { useEffect, useRef, useState } from 'react'
// import {graphql, useStaticQuery} from 'gatsby'
import { Helmet } from 'react-helmet'
// import HeaderSEO from '../components/partials/HeaderSEO'
import Spacing from '../components/partials/Spacing'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import BlogSection from '../components/sections/BlogSection'

const EnterpriseSupportOk = () => {
    //   const data = useStaticQuery(graphql`
    //   query FormTrialSubmittedSEO {
    //     allContentfulPages(
    //       filter: {
    //         pageReference: { pageIdentifier: { eq: "Form_TrialSubmitted" } }
    //       }
    //     ) {
    //       edges {
    //         node {
    //           title {
    //             title
    //           }
    //           keywords {
    //             keywords
    //           }
    //           description {
    //             description
    //           }
    //           oGimage {
    //             fluid(quality: 100) {
    //               src
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // `)

    // const pageSEO = data.allContentfulPages.edges[0].node
    const ref = useRef(null)
    const [video, setVideo] = useState({ isOpen: false, title: '', url: '' })

    const blogs = [
        {
            image: (
                <StaticImage
                    className='w-100 rounded-top-1'
                    src='../resources/images/screenshots/Topic_02.png'
                    placeholder='tracedSVG'
                    alt='S3 connector'
                />
            ),
            title: (
                <div>
                    <p className='d-inline cera-pro'>
                        Lenses Amazon S3 Sink Connector to backup/ restore Kafka topic data
                    </p>
                </div>
            ),
            type: 'VIDEO',
            callback: () =>
                setVideo({
                    isOpen: true,
                    title: 'Lenses 5.3 in 30 minutes (new connector experience)',
                    url: '//player.vimeo.com/video/874893270',
                }),
            url: 'https://vimeo.com/manage/videos/874893270',
        },
        {
            image: (
                <StaticImage
                    className='w-100 rounded-top-1'
                    src='../resources/images/screenshots/simplyfingkafka.png'
                    placeholder='tracedSVG'
                    alt='Data Capture and Kafka'
                />
            ),
            title: (
                <div>
                    <p className='d-inline cera-pro'>
                        {' '}
                        Learn how to integrate and simplify Kafka with Lenses connectors
                    </p>
                </div>
            ),
            type: 'LIVE STREAM',
            openInNewTab: true,
            url: 'https://celonis.zoom.us/webinar/register/WN_8sa_B5O6SSSKHPWft11O1w#/registration ',
        },
        {
            image: (
                <StaticImage
                    className='w-100 rounded-top-1'
                    src='../resources/images/screenshots/S3Connector.jpg'
                    placeholder='tracedSVG'
                    alt='Data Capture and Kafka'
                />
            ),
            title: (
                <div>
                    <p className='d-inline cera-pro'>
                        Kafka Amazon S3 Connectors: the latest features
                    </p>
                </div>
            ),
            type: 'BLOG',
            url: '/blog/2023/09/open-source-lenses-kafka-s3-connector/',
        },
    ]

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setVideo((current) => ({ ...current, isOpen: false }))
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)

        // Unbind the event listener on cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <Layout>
            {/*<HeaderSEO pageSEO={pageSEO}/>*/}
            <Helmet>
                <meta name='robots' content='noindex' />
                <meta name='robots' content='nofollow' />
            </Helmet>
            <StaticImage
                className='home-fixed-top'
                src='../../resources/images/homepage/hero_lens.svg'
                placeholder='blurred'
                alt='Hero Lenses'
            />
            <section className='hero-margin pb-4'>
                <div className='container-1'>
                    <div className='row mt-3'>
                        <div className='col-md-8 offset-2 text-center pt-0'>
                            <h1 className='mt-3'>Schedule a connector demo with our team</h1>
                            <br />
                            <p className='hero-description-dark'>
                                Book a 20 minute call with a Lenses specialist to give you a guided
                                tour of our Kafka Connectors and get started.
                            </p>
                            <div
                                className='roll-button navbar-dl-button mobile-padding-left mobile-margin-top-half'
                                style={{ margin: '30px 0 0 0' }}>
                                <a
                                    id='navbar-download'
                                    style={{ fontSize: '1rem' }}
                                    className='text-white mr-0 bg-red'
                                    target={'_blank'}
                                    href='https://lenses.chilipiper.com/book/me/sandra-czajkowska1?type=my-meeting'
                                    rel='noreferrer'>
                                    <span data-hover='Book a demo'>Book a demo</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>{' '}
            </section>

            <Spacing />
            <BlogSection title={'Brush up on Lenses Kafka connectors'} blogs={blogs} />
            {video.isOpen && (
                <div
                    className='videoContainer'
                    ref={ref}
                    style={{
                        position: 'fixed',
                        boxShadow: '0 4px 25px 22px rgba(0, 0, 0, 0.3)',
                        zIndex: 9999,
                        width: '100%',
                        height: '100%',
                        maxWidth: '768px',
                        maxHeight: '460px',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}>
                    {/*<StaticImage*/}
                    {/*    src={'../../resources/images/icons/close-icon.svg'}*/}
                    {/*    alt={'close icon'}*/}
                    {/*    className={'webinar-close-btn'}*/}
                    {/*    onClick={() => setVideo((current) => ({...current, isOpen: false}))}*/}
                    {/*/>*/}
                    <iframe
                        src={video.url}
                        title={video.title}
                        style={{
                            // position: 'absolute',
                            background: 'black',
                            width: '100%',
                            height: '100%',
                        }}
                        frameBorder='0'
                        allow='autoplay; fullscreen; picture-in-picture'
                        allowFullScreen></iframe>
                </div>
            )}
        </Layout>
    )
}
export default EnterpriseSupportOk
